.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    overflow-x: auto;
}

.title {
    margin-bottom: 10px;
    font-weight: bold;
}

.competitionSystemDisplay {
    margin-bottom: 10px;
    font-style: italic;
}

.linkSystems {
    color: #007bff;
}

.linkSystems:hover {
    text-decoration: underline;
}

.refreshButton {
    cursor: pointer;
    background-color: transparent;
    color: blue;
    border: none;
}

.refreshButton:hover {
    background-color: transparent;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
    margin-left: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.refreshButtonAnimating {
    animation: spin 1s linear infinite;
}

.scoreRules {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.scoreRules a {
    background-color: #f0f0f0;
    color: #333;
    padding: 5px 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
}

.scoreRules a:hover {
    background-color: #e0e0e0;
    transform: translateY(-5px);
}

.generateButton {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #5aa0e7;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
}

.generateButton:hover {
    opacity: 0.85;
}

.returnToPhaseGroupButton {
    padding: 8px 16px;
    font-size: 16px;
    background-color: white;
    color: #a69c65;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
}

.returnToPhaseGroupButton:hover {
    opacity: 0.85;
}

.groupsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 100%;
}

.group {
    flex: 1 1 calc(25% - 20px);
    max-width: calc(25% - 20px);
    background-color: #f1eded;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
}

@media (max-width: 1200px) {
    .group {
        flex: 1 1 calc(33.333% - 20px);
        max-width: calc(33.333% - 20px);
    }
}

@media (max-width: 768px) {
    .group {
        flex: 1 1 100%;
        max-width: 100%;
    }
}

.finalPhaseTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 1.3rem;
}

.groupPhaseTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.3rem;
}

.lookingInput {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
    width: 140px;
    margin-bottom: 10px;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    max-width: 350px; /* Ogranicza szerokość kontenera do 800px */
    margin: 0 auto;
}

.returnCurButton {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Wyrównanie do lewej */
    color: #0f172a;
    background: none;
    font-size: 1.5rem;
    padding: 0;
    height: 20px;
    width: 25px;

}

.returnCurButton:hover {
    background: #e8e8e8;
}