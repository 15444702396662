.bracketContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; /* Start alignment allows for spacing control */
    gap: 20px; /* Space between rounds */
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    background-color: #f1eded;
}

.round {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.matchCellForSingleEliminationBracket {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; /* This will push the button to the left */
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
    width: 350px;
    text-align: center;
    border-radius: 5px;
}

.playerNames div {
    text-align: left;
}

.playerNames div:not(:last-child) {
    margin-bottom: 10px;
}

.resultOfMatch {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    border: none;
}


.titleOfRound {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    background-color: #bbc7d3;
    border: 1px solid #ddd;
    padding: 3px;
    width: 350px;
    text-align: center;
    border-radius: 5px;
}

.round {
    margin-bottom: 40px;
}

.button {
    font-size: small;
    padding: 7px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: darkgray;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.button:hover {
    background-color: #0056b3;
}

.navigation {
    display: none;
}


.titleOfRoundContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigationButton {
    display: none;
}


.thirdPlace {
    margin-top: 20px; /* Adds some space between the final and the third place match */
}


.finalRounds {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleOfThirdPlaceRound {
    background-color: #bbc7d3;
    border: 1px solid #ddd;
    padding: 3px;
    width: 270px; /* Or whatever width you are using for the other titles */
    text-align: center;
    border-radius: 5px;
}


/* Adjustments for mobile view */
@media (max-width: 600px) {
    .navigation {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        background-color: transparent;
        /*margin-bottom: 20px;*/
    }

    .navigationButton {
        justify-content: center;
        display: inline;
        background-color: transparent;
        border: none;
        color: #007bff;
        cursor: pointer;
        padding: 0;
        font-size: 1.2rem;
    }

    .navigationButton:hover {
        color: transparent;
    }

    .bracketContainer {
        flex-direction: column;
        align-items: center;
    }

    .round {
        display: none; /* Hide all rounds initially */
    }

    /* Display the round based on the current state */
    .round1.current,
    .round2.current,
    .round3.current,
    .round4.current {
        display: flex;
    }

}

