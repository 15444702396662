.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin: auto;
    max-width: 1200px;
}

.linksContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.reserveTitle {
    font-size: 20px;
}

.startTime {
    text-align: center;
    font-size: 20px;
}

.description {
    text-align: justify;
    width: 95%; /* Make full width on small screens */
    max-width: 800px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.participantLimit {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
}

.registrationEndDate {
    text-align: center;
    font-size: 20px;
}

.button, .logInButton, .addMeButton, .removeMeButton {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    transition: background-color 0.2s ease-in-out;
    color: white;
    display: block;
    /*width: 100%; !* Full width for better touch target on small devices *!*/
}

.button:hover, .logInButton:hover, .addMeButton:hover, .removeMeButton:hover {
    background-color: #e0e0e0;
    color: black;
}

.logInButton {
    background-color: #4CAF50;
}

.addMeButton {
    background-color: #388eea;
}

.removeMeButton {
    background-color: #0d5cab;
}

.table {
    width: 100%; /* Full width table */
    border-collapse: collapse;
    margin-top: 20px;
    max-width: 800px;
}

.reserveTable {
    width: 100%; /* Full width table */
    border-collapse: collapse;
    margin-top: 5px;
    max-width: 800px;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th:nth-child(1), td:nth-child(1) {
    text-align: center;
}

.table th {
    background-color: #f9f9f9;
}

.table tr:nth-child(odd) {
    background-color: #fcfcfc;
}

.table tr:hover {
    background-color: #f1f1f1;
}

.countdownContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}

.timeBox {
    background-color: orangered;
    color: white;
    padding: 10px;
    min-width: 60px;
    border-radius: 5px;
    text-align: center;
    font-size: 2rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.timeLabel {
    text-align: center;
    color: #fff;
    font-size: 1rem;
}

.buttonContainer {
    display: flex;
    justify-content: center; /* Centers the button horizontally */
    /*margin-top: 20px; !* Optional: adds space above the button *!*/
}

.firstRegistration {
    margin-top: 20px;
    font-size: 16px;
    font-style: italic;
}

.startRegistrationDate{
    font-size: 16px;
}
/* Responsive adjustments */
@media (max-width: 768px) {
    .container {
        margin: 10px;
        padding: 10px;
    }

    .title, .reserveTitle, .button, .logInButton, .addMeButton, .removeMeButton, .startTime, .registrationEndDate, .participantLimit  {
        font-size: 16px; /* Smaller font size for smaller screens */
        text-align: center;
    }

    .description {
        text-align: left;
    }

    .table {
        display: block;
        overflow-x: auto; /* Allow table to scroll horizontally on very small screens */
    }

    .timeBox {
        font-size: 1.5rem; /* Smaller countdown numbers */
    }

    .timeLabel {
        font-size: 0.8rem; /* Smaller labels for countdown */
    }
}

.partnerEmailInput {
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 5px;
    margin-bottom: 10px;
    transition: border-color 0.2s ease-in-out;
}

.partnerEmailInput:focus {
    border-color: #388eea;
    outline: none;
}

.partnerEmailInput::placeholder {
    color: #a8a8a8;
}

.partnerEmailInput:invalid {
    border-color: #e74c3c;
}

.scoreRules {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scoreRules a {
    background-color: #f0f0f0; /* Light gray background */
    color: #333; /* Dark text color for contrast */
    padding: 5px 15px; /* Padding inside each link/card */
    border-radius: 8px; /* Rounded corners for the card-like appearance */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 100%; /* Or a specific width, adjust as necessary */
    text-align: center; /* Centers the text within each link/card */
    text-decoration: none; /* Removes the underline from links */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}
.scoreRules a:hover {
    background-color: #e0e0e0; /* Slightly darker background on hover */
    transform: translateY(-5px); /* Lifts the card up a bit */
}

.messengerLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.messengerLink a {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #f0f0f0; /* Light gray background */
    color: #333; /* Dark text color for contrast */
    padding: 5px 15px; /* Padding inside each link/card */
    border-radius: 8px; /* Rounded corners for the card-like appearance */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 100%; /* Or a specific width, adjust as necessary */
    text-align: center; /* Centers the text within each link/card */
    text-decoration: none; /* Removes the underline from links */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}
.messengerLink a:hover {
    background-color: #e0e0e0; /* Slightly darker background on hover */
    transform: translateY(-5px); /* Lifts the card up a bit */
}


.isLoading {
    text-align: center;
}

.returnButton{
  color: #0f172a;
    background: none;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 0;
    height: 20px;
}

.returnButton:hover{
    background: #e8e8e8;
}