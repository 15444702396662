.modalOverlay {
    position: fixed; /* Fixed position to cover the whole screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
    z-index: 1000; /* High z-index to be on top of other content */
}


.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form {
    display: flex;
    flex-direction: column;
}


.setGroup {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.playerGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.playerName {
    margin-bottom: 10px;
}

.scoreInput {
    margin-bottom: 10px; /* Adjust spacing between inputs */
}


.setRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.setRow label {
    margin-right: 10px;
    font-weight: bold;
}

.scoreInput {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    margin: 0 5px;
    width: 60px; /* Adjust width as needed */
    text-align: center;
    font-size: 1rem;
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 5px;
}

.saveButton, .backButton {
    background-color: #007bff; /* Primary color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.saveButton:hover, .backButton:hover {
    background-color: #0056b3; /* Darker primary color on hover */
}

.backButton {
    background-color: #6c757d; /* Secondary color */
}

.backButton:hover {
    background-color: #5a6268; /* Darker secondary color on hover */
}

.setInputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tiebreakInput {
    margin-left: 8px; /* Space between game score input and tiebreak input */
}

.playerRow {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.playerScores {
    margin-bottom: 20px;
}

.setGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.scoreInputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.tiebreakCheckbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.tiebreakInputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.scoreInput {
    flex: 1;
    margin-right: 10px; /* Space between inputs */
    /* Other styles like padding, border, etc. */
}

.scoreInput:last-child {
    margin-right: 0;
}

.tiebreakCheckbox input[type="checkbox"] {
    margin-right: 5px;
}

.participantNames {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    gap: 20px;
}

.participantNames h5 {
    font-size: 1rem;
    font-weight: bold;
}

.checkboxGroup, .radioGroup {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.checkboxGroup label, .radioGroup label {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Optional: Custom styling for checkboxes and radio buttons */
.checkboxGroup input[type="checkbox"], .radioGroup input[type="radio"] {
    accent-color: #007bff; /* Change to match your theme color */
    cursor: pointer;
}

/* Styling for labels to make text more readable */
.checkboxGroup label, .radioGroup label {
    cursor: pointer;
}

.question {
    margin: 5px;
}
