.scoreRulesContainer {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 30px auto;
    max-width: 800px;
}

.scoreRulesContainer h1 {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.4em;
}

.scoreRulesText {
    margin-top: 20px;
}

.scoreRulesText h5 {
    font-size: 1.2em;
    margin-top: 20px;
    color: #333;
}

.scoreRulesText p:first-child {
    margin-top: 10px;
}

.scoreRulesText p {
    margin-top: 10px;
    text-align: justify;
    color: #666;
}

.scoreRulesText li {
    margin-top: 5px;
}


.nextRow{
    margin: 0;
}

.preliminaryDescription{
    font-style: italic;
}

.competitionDescription{
    margin: 0;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.picturesOfCompetition {
    max-width: 100%;
    height: auto;
}

.titleOfCompetition {
    font-size: 1em;
}