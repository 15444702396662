.section {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Ustawienie obrazka na górze sekcji */
    padding-top: 20px; /* Dodanie odstępu 20px od góry */
    height: calc(100vh - 20px); /* Ustalanie wysokości sekcji, uwzględniając odstęp */
}

.page404 {
    border-radius: 20px; /* Zaokrąglone rogi */
    max-width: 100%; /* Obrazek będzie się skalował, ale nie przekroczy swojej naturalnej szerokości */
    max-height: 100%; /* Obrazek będzie się skalował, ale nie przekroczy swojej naturalnej wysokości */
    object-fit: cover; /* Dopasowanie obrazka bez zniekształcania */
}



