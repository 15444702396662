.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.userCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: box-shadow 0.3s ease;
}

.infoCard{
    font-size: 14px;
}

.infoPartner{
    margin-top: 20px;
    font-size: 1rem;
}

.rangeSelect {
   max-width: 80px;
}

.coachCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: box-shadow 0.3s ease;
}

.contactInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.preferContact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    font-size: 1rem;
}

.contactIcons {
    display: flex;
    color: darkseagreen;
    gap: 20px;
    font-size: 30px;
    /*cursor: pointer;*/
}

.preferContactIcons {
    display: flex;
    flex-direction: row;
    color: darkseagreen;
    gap: 20px;
    font-size: 20px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;

}

.userDataContainer {
    margin: 0 auto;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    align-items: center;
}

.userDataContainer button {
    max-width: 200px;
}

.title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    text-align: left;
    width: 100%;
}

.dataRow {
    display: flex;
    flex-direction: column;
    /*margin-bottom: 0.1rem;*/
}

.label {
    display: block; /* Ensure label is on its own line for clarity */
    font-size: 1rem;
    margin-bottom: 8px; /* Adjusted space between label and input */
    font-weight: bold; /* Emphasize label */
}

.input {
    width: 100%;
    padding: 12px; /* Increased padding for better readability */
    margin-bottom: 20px; /* Increased space between fields */
    font-size: 16px;
    border: 2px solid #ccc; /* Slightly thicker border for emphasis */
    border-radius: 6px; /* Rounded corners for modern look */
    box-sizing: border-box; /* Ensures padding doesn't affect overall width */
}

.value {
    font-weight: bold;
}

.button {
    padding: 10px 20px; /* More padding for larger, easier-to-click buttons */
    font-size: 16px;
    border: none;
    border-radius: 6px; /* Rounded corners for buttons */
    cursor: pointer;
    transition: background-color 0.3s, opacity 0.2s; /* Smooth transition for hover effect */
}


.button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.button[type="button"] {
    background-color: #6c757d; /* Neutral color for cancel button */
    color: white;
}

.button[type="submit"] {
    background-color: #004085; /* Darker blue for more contrast */
    color: white;
}

.button:hover {
    opacity: 0.85;
}

.table {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    width: 100%;
    border-collapse: collapse;
}

.tableRow {
    border-bottom: 1px solid #ddd;
    padding: 8px;
}

.label {
    font-weight: bold;
    padding: 1px;
}

.value {
    padding: 8px;
}

@media (max-width: 768px) {
    .userDataContainer {
        padding: 10px;
        gap: 15px;
    }
}

textarea {
    width: 100%;
    height: 200px;
    padding: 12px;
    box-sizing: border-box; /* Ensures padding does not affect the overall dimensions */
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: vertical; /* Allows resizing only vertically */
    font-size: 16px; /* Larger font for better readability */
    margin-bottom: 10px;
}

.checkboxContainer {
    margin-bottom: 1rem; /* Space between checkbox rows */
    display: flex;
    align-items: center; /* Ensures vertical alignment */
    gap: 1rem; /* Adjusts the space between the checkbox and the label text */
}

.checkboxLabel {
    margin-left: 1rem;
    display: flex;
    align-items: center; /* This line vertically centers the flex items (checkbox and text) */
    gap: 8px; /* Optional: Adjusts the space between the checkbox and the label text
}

.labelText {
    margin-left: 1rem; /* Adds space between the checkbox and the label text */
}

.isLoading {
    text-align: center;
}