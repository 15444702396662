.container {
    margin-bottom: 1rem;
}


.dateInfo {
    display: inline-flex;
    align-items: center;
}

.checkboxAfter14 {
    display: inline-flex;
    align-items: center;
    margin-left: 20px;
}

.checkboxAfter14 label {
    margin-left: 5px;
}

.dateAndCheckboxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

