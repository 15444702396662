.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.historyContainer {
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin: 0 auto;
    text-align: center;
}

.backButton {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the left */
    color: #0f172a;
    background: none;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 0;
    height: 20px;
}

.backButton:hover{
    background: #e8e8e8;
}

.rankings {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.switch {
    display: flex;
    margin-bottom: 20px;
}

.switch button {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ddd;
    background-color: #ffffff;
    margin: 0 5px;
    color: #b4b0b0;
}

.switch button.active {
    background-color: #b4b0b0;
    font-weight: bold;
    color: white;
}

.ranking {
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin: 0 auto;
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto; /* Center the table */
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}

.table th:nth-child(1),
.table th:nth-child(2) {
    text-align: center;
}

.table td:nth-child(1),
.table td:nth-child(2) {
    text-align: center;
}

.table td a {
    text-decoration: none;
    color: #007bff;
}

.table td a:hover {
    text-decoration: underline;
}

.title {
    text-align: center;
    margin-bottom: 5px;
    font-size: 1.1rem;
}

.buttonOfDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.buttonOfDescription a {
    text-align: center; /* Centers the text within each link/card */
    text-decoration: none; /* Removes the underline from links */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
    font-size: 1.1rem;
}

.buttonOfDescription a:hover {
    transform: translateY(-5px); /* Lifts the card up a bit */
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
}

.modalContent {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 10px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.closeButton {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.closeButton:hover,
.closeButton:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.button {
    font-size: small;
    padding: 7px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: darkgray;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.button:hover {
    background-color: #0056b3;
}

.result {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    margin: 10px 0;
    max-width: 400px;
    gap: 20px;
}

.playerNames div {
    margin-bottom: 10px;
    text-align: left;
}

.partnerName {
    display: block;
    margin-top: 5px;
}

.setWinning {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
}

.setResults span {
    margin-right: 5px;
    white-space: nowrap;
}

.resultOfMatch {
    display: flex;
    gap: 15px;
    border: none;
}

.titleInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
