.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: auto;
    max-width: 1200px;
}

.title {
    margin-bottom: 10px;
}

.addOfferContainer {
    margin-bottom: 20px;
    text-align: center;
}

.addOfferButton {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
}

.addOfferButton:hover {
    background-color: #45a049;
}

.formContainer {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f3f3f3;
    border-radius: 8px;
}

.formContainer label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formContainer input,
.formContainer select,
.formContainer textarea,
.formContainer button {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    font-size: 1.2em;
}

.submitButton {
    background-color: #4CAF50;
    color: white;
}

.offerContainer {
    margin: 0 auto;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    justify-items: center;
    max-height: 80vh; /* Set maximum height for vertical scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
}

@media (max-width: 600px) {
    .offerContainer {
        margin: 0 auto;
        max-width: 960px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
    }
}


.offerCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    width: 300px;
    position: relative;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
}

.boldText {
    font-weight: bold;
}

.pastOfferCard {
    background-color: #f0f0f0;
    color: #999;
}


.contactIcons {
    display: flex;
    color: darkseagreen;
    gap: 20px;
    font-size: 30px;
    cursor: pointer;
}

.contactIcons > svg {
    transition: transform 0.2s ease;
}

.contactIcons > svg:hover {
    transform: scale(1.4);
}

.ownerButtons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: flex-end; /* Align buttons to the right */
    right: 10px;
    bottom: 10px;
}

.ownerButtons button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.ownerButtons button:nth-child(1) {
    background-color: #2196F3;
    color: white;
}

.ownerButtons button:nth-child(1):hover {
    background-color: white;
    color: #2196F3;
    border: 1px solid #2196F3;
}

.ownerButtons button:nth-child(2) {
    background-color: #F44336;
    color: white;
}

.ownerButtons button:nth-child(2):hover {
    background-color: white;
    color: #F44336;
    border: 1px solid #F44336;
}

.offerDate {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 1.1rem;
}

.offerTime {
    margin-top: auto;
    font-size: 1.1rem;
    margin-bottom: 15px;
}

.addInfo {
    margin-top: auto;
    font-style: italic;
}

.titleInfo {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.infoButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.infoButton a {
    text-align: center; /* Centers the text within each link/card */
    text-decoration: none; /* Removes the underline from links */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
    font-size: 1.1rem;
}

.infoButton a:hover {
    transform: translateY(-5px); /* Lifts the card up a bit */
}