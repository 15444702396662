.tableContainer {
    overflow-x: auto; /* Enables horizontal scrolling when necessary */
    width: 100%; /* Ensures the container takes full width of its parent */
    margin: 0 auto; /* Centers the container */
}

.MyReservations {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

h1 {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
    text-align: center;
}

.radioButtons {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.radioButtons input[type="radio"] {
    margin-right: 5px;
}

.radioButtons label {
    margin: 0 0.5rem;
}

.reservationsTable {
    width: 100%; /* Ensures the table takes the full width of its container */
    min-width: 600px; /* Minimum width of the table; adjust as needed based on your content */
    border-collapse: collapse; /* Optimizes the appearance of the borders */
    margin-bottom: 20px; /* Provides space below the table */
}

.reservationsTable th,
.reservationsTable td {
    border: 1px solid #ccc; /* Styles the border */
    padding: 8px; /* Padding inside cells for better readability */
    text-align: center; /* Centers the text within cells */
}

@media screen and (max-width: 640px) {
    .reservationsTable, .tableContainer {
        width: 100%;
    }

    .reservationsTable th, .reservationsTable td {
        padding: 10px; /* Adjust padding to ensure content doesn't feel cramped on smaller screens */
    }
}

.button {
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 50%;
    color: gray;
    width: 24px;
    height: 24px;
    padding: 0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    line-height: 22px;
    margin: 0.2rem 0.2rem;
    cursor: pointer;
}

.button:hover {
    background-color: #ff4b4b;
    color: #ffffff;
}

.cancelButtonContainer {
    padding: 0;
}

.cancelledReservation {
    color: red;
}

.noReservations {
    font-size: 1rem;
    font-weight: normal;
    color: gray;
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.pageNumber {
    margin: 0 1rem;
}

.prevNextButton {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    font-weight: bold;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.prevNextButton:hover {
    background-color: darkgrey;
    color: #ffffff;
    border-color: transparent;
}

.prevNextButton:disabled {
    background-color: #f8f8f8;
    color: #ccc;
    border-color: #ccc;
    cursor: not-allowed;
}


.cancelledReservation {
    color: gray;
}

.payedReservation {
    color: green;
}

.expiredReservation {
    color: #dc4813;
}

.error {
    margin: 20px;
    padding: 10px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    border-radius: 4px;
    text-align: center;
}

.isLoading {
    text-align: center;
}