.newsList {
    max-width: 800px;
    margin: 0 auto;
}

.newsItem {
    border: 1px solid #e0e0e0;
    padding: 0.5rem;
    margin: 1rem 0;
    border-radius: 4px;
    background-color: #f5f5f5;
}

.newsDate {
    margin-bottom: 0.5rem;
    font-size: 0.6rem;
}

.newsItem {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.newsItem:hover {
    background-color: #e9e9e9;
}

.newsTitle {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #007bff; /* Make the title look clickable */
}

.newsDescription {
    font-size: 0.8rem;
    line-height: 1.5;
    white-space: pre-wrap;
    padding: 0.5rem 0;
}

.isLoading {
    text-align: center;
    margin-top: 20px;
}