.PaymentOnline {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.paymentsTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
}

.paymentsTable th,
.paymentsTable td {
    border: 1px solid #ccc;
    padding: 0.5rem;
    text-align: center;
    vertical-align: middle;
}

.tableContainer {
    overflow: auto;
    margin: 1rem 0;
}

.MyReservations {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

h1 {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
    text-align: center;
}

.radioButtons {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.radioButtons input[type="radio"] {
    margin-right: 5px;
}

.radioButtons label {
    margin: 0 0.5rem;
}

.reservationsTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
}

.reservationsTable th,
.reservationsTable td {
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;
    text-align: center;
    vertical-align: middle;
}

.button {
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 50%;
    color: gray;
    width: 24px;
    height: 24px;
    padding: 0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    line-height: 22px;
    margin: 0.2rem 0.2rem;
    cursor: pointer;
}

.button:hover {
    background-color: #ff4b4b;
    color: #ffffff;
}

.cancelButtonContainer {
    padding: 0;
}

.cancelledReservation {
    color: red;
}

.noReservations {
    font-size: 1rem;
    font-weight: normal;
    color: gray;
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
}

/* Nowe style dla stronicowania */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.pageNumber {
    margin: 0 1rem;
}

.prevNextButton {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    font-weight: bold;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.prevNextButton:hover {
    background-color: darkgrey;
    color: #ffffff;
    border-color: transparent;
}

.prevNextButton:disabled {
    background-color: #f8f8f8;
    color: #ccc;
    border-color: #ccc;
    cursor: not-allowed;
}

.cancelledReservation {
    color: gray;
}

.payedReservation {
    color: green;
}

.expiredReservation {
    color: #dc4813;
}

.titlePaymentHistory {
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.noDataMessage {
    text-align: center;
    font-size: 1.2em;
    margin: 20px 0;
    font-style: italic;
}
