.container {
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
    padding: 2rem;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
}

th,
td {
    text-align: left;
    padding: 0.5rem;
    border: 1px solid #ccc;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f8f8f8;
}

.actions {
    display: flex;
    justify-content: space-around;
}

.button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buttonEdit {
    background-color: #007bff;
    color: white;
}

.buttonEdit:hover {
    background-color: #0056b3;
}

.buttonDelete {
    background-color: #dc3545;
    color: white;
}

.buttonDelete:hover {
    background-color: #c82333;
}

.buttonAdd {
    background-color: #007bff;
    color: white;
    margin-top: 10px;
}

.buttonAdd:hover {
    background-color: #0056b3;
}

.buttonUpdate {
    background-color: #007bff;
    color: white;
    margin-top: 10px;
}

.buttonUpdate:hover {
    background-color: #0056b3;
}

.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.input {
    margin-left: 10px;
    margin-top: 0.5rem;
    max-width: 70px;
    text-align: right;
}

.rightAligned {
    text-align: right;
}
