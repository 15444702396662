/* Regulation.module.css or Statute.module.css */
.regulationContainer {
    /*text-align: center;*/
    /*padding: 30px;*/
    /*background-color: #fff;*/
    /*border-radius: 5px;*/
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
    /*margin: 30px;*/
    /*max-width: 800px;*/

    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 30px auto;
    max-width: 800px;
    font-family: 'Times New Roman', Times, serif; /* This font is commonly used in formal documents */
}

.regulationContainer h1 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.regulationContainer h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
    text-align: center;
}

.regulationContainer h3 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.regulationText {
    margin-top: 5px;
    font-size: 16px;
}

.regulationText p {
    text-align: left;
}

/* Style for the section number '§' */
.sectionNumber {
    font-weight: bold;
    margin-top: 16px;
    text-align: center;
}
