.title {
    text-align: center;
    font-size: 1.2rem; /* Adjust the size as needed */
    color: #333; /* Adjust the color as needed */
    margin-top: 20px;
    margin-bottom: 5px; /* Adds space between the title and the cards */
    font-family: 'Calibri Light', sans-serif; /* Adjust the font family as needed */
    /*font-weight: bold;*/
}

@media (max-width: 600px) {
    .title{
        font-size: 1rem;
    }
}

.coachesContainer {
    margin: 0 auto;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.coachCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: box-shadow 0.3s ease;
}

.coachCard:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.contactIcons {
    display: flex;
    color: orange;
    gap: 20px;
    font-size: 30px;
    cursor: pointer;
}

.contactIcons > svg {
    transition: transform 0.2s ease;
}

.contactIcons > svg:hover {
    transform: scale(1.1);
}
