.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between rows */
    padding: 20px;
    background-color: #fff; /* Optional: for better contrast */
    border-radius: 8px; /* Optional: for aesthetic appeal */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: for a subtle shadow */
    align-items: center;
}

.formRow {
    display: flex;
    justify-content: space-between; /* Adjust as needed for your layout */
    gap: 10px; /* Space between fields within a row */
}


.form {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for larger screens */
    gap: 20px; /* Space between fields */
    margin-bottom: 20px; /* Space below the form before the table */
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.input, select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px; /* Space below each input */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.input:focus, select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.buttonAdd, .buttonUpdate {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.buttonAdd:hover, .buttonUpdate:hover {
    background-color: #0056b3;
}


.buttonEdit {
    background-color: #ffc107;
}

.buttonEdit:hover {
    filter: brightness(90%);
}

.buttonDelete {
    background-color: #dc3545;
}

.buttonDelete:hover {
    filter: brightness(90%);
}

.buttonStart {
    background-color: green;
}

.tableContainer {
    width: 100%; /* Ensures the container takes up the full width */
    overflow-x: auto; /* Enables horizontal scrolling */
}

.table {
    width: 100%; /* Table takes full width of the container */
    min-width: 600px; /* Minimum width to ensure table content is readable */
    border-collapse: collapse;
    margin-top: 20px; /* Space above the table */
}

th, td {
    text-align: left;
    padding: 12px;
    border: 1px solid #ccc;
}

th {
    background-color: #f2f2f2;
}

tr:nth-child(even) {
    background-color: #f8f8f8;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 8px; /* This adds some space between your buttons */
}

.textarea {
    width: 100%;
    height: 200px;
    padding: 12px;
    box-sizing: border-box; /* Ensures padding does not affect the overall dimensions */
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: vertical; /* Allows resizing only vertically */
    font-size: 16px; /* Larger font for better readability */
    margin-bottom: 10px;
}

.buttonEdit,
.buttonDelete,
.buttonPlayers,
.buttonStart {
    display: block; /* Makes the button a block element */
    width: 100%; /* Optional: if you want each button to take the full width of the cell */
    /*margin-bottom: 1px; !* Adds space below each button except the last one *!*/
    text-align: center; /* Centers the text inside the button */
    font-size: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: filter 0.2s ease-in-out;
}

.checkbox {
    margin-left: 10px;
    vertical-align: middle;
}

@media (max-width: 768px) {
    .formContainer, .formRow, .input, .select, .textarea, .buttonEdit, .buttonDelete, .buttonStart {
        flex-direction: column;
        width: 100%;
    }

    .input, .select, .textarea {
        font-size: 14px; /* Smaller font size for better fit on small screens */
    }

    .buttonEdit, .buttonDelete, .buttonStart {
        font-size: 12px; /* Smaller buttons on small screens */
    }
}

.addTournament {
    border-radius: 5px;
}

.access {
    margin-top: 20px;
    text-align: center;
}