.cell {
    width: 80px;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px; /* zmniejszanie rozmiaru czcionki*/
    white-space: nowrap; /* zapobieganie zawijaniu tekstu */
    overflow: hidden; /* ukrywa tekst, który nie mieści się w komórce */
    text-overflow: ellipsis; /* dodaje trzy kropki na końcu obciętego tekstu */
    margin: 2px 5px 2px 2px;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.member {
    background-color: lightgreen;
}

.periodic {
    background-color: lightblue;
}

.blocked {
    background-color: orangered;
}

.registered {
    background-color: yellow;
}

.payed {
    background-color: pink;
}

.lessons {
    background-color: #e8e8e8;
}

.nmember {
    background-color: darkseagreen;
}