.cameraWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Ustaw odpowiednią szerokość i wysokość dla swojego kontenera */
    width: 100%;
    height: 100%;
}

.centeredImage {
    /* Opcjonalnie: możesz ustawić maksymalną szerokość i wysokość obrazu */
    max-width: 100%;
    max-height: 100%;
}
