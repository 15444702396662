.timeCell {
    /* Ustaw szerokość i wysokość komórki na taką samą, jaką ustalono dla ReservationCell */
    width: 40px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
}


.cell {
    width: 80px;
    height: 40px;
    border: 1px solid #ccc;
    margin: 2px;
    border-radius: 6px;
}