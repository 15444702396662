.container {
    width: 100%; /* Ensures the container takes the full width */
    max-width: 100%; /* Prevents the container from exceeding the viewport width */
    overflow-x: hidden; /* Hides horizontal overflow to prevent horizontal scrolling */
    padding: 20px; /* Provides padding around the content */
}


.myTab {
    padding: 4px 10px; /* Adjust padding as needed */
    min-width: 5px; /* Reduce minimum width to decrease gap */
}

/* Optional: Reduce the font size for smaller gaps */
.myTab .MuiTab-wrapper {
    font-size: 0.8rem;
}

@media (max-width: 768px) {
    .container {
        padding: 10px; /* Reduces padding on smaller screens */
    }

    .tabContent { /* Class you might add to each TabPanel for targeted styling */
        padding: 5px; /* Reduces padding inside tab panels */
        overflow-x: auto; /* Allows horizontal scrolling within tabs if needed */
    }
}
