.AdminPayments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    text-align: center; /* Dodane */
}

.tableContainer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Dodano */
    justify-content: center; /* Dodano */
    width: 100%;
    overflow-x: auto;
    margin: 0 auto; /* Centrowanie kontenera */
    text-align: center;
}

.reservationsTable {
    margin-left: auto;
    margin-right: auto; /* Horizontally center the table */
    border-collapse: collapse;
    width: auto; /* or max-width: 100% if you want it to be responsive */
}

.reservationsTable th,
.reservationsTable td {
    padding: 8px; /*Adequate padding for content*/
    border: 1px solid #ddd; /*Subtle border for cells*/
    /*overflow: hidden; !*Ensures content doesn't spill out*!*/
    text-overflow: ellipsis; /* Adds an ellipsis to overflowing text*/
}

.paidButton {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.paidButton:hover {
    background-color: #45a049;
}

.filterContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between filters */
    margin-bottom: 10px; /* Space above the table */
    justify-content: center;
    padding: 10px;
}

.datepicker {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
    width: 100px;
    margin-bottom: 10px;
}

.cost {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
    width: 70px;
    margin-bottom: 10px;
}

.totalUnpaid {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
}

.reservationsTable {
    width: auto;
    border-collapse: collapse;
    margin: 20px auto auto;
}

.reservationsTable th, .reservationsTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.paidButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
}

.paidButton:hover {
    background-color: #45a049;
}


.value {
    font-weight: bold;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


.button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}