.buttonWithProjection {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
}

.reserveButton {
    font-size: 1.2rem;
    background-color: rgba(255, 69, 0, 0.5);
    color: white;
    border-radius: 8px;
    padding: 5px 10px;
    transition: background-color 0.3s, color 0.3s;
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reserveButton:hover {
    background-color: #4fbfda;
    color: rgba(255, 69, 0, 1);
}

.holdReservation {
    background-color: yellow;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    transition: background-color 0.3s, color 0.3s;
    border: 1px #4fbfda solid;
}

.reservationProjection {
    width: 100%;
    line-height: 0.1;
    margin-right: 20px;
}

.reservationProjectionContainer {
    background-color: rgba(76, 175, 80, 1);
    color: white;
    padding: 5px;
    margin-top: 5px;
    text-align: center;
    white-space: nowrap;
    border-radius: 8px;
    /*display: flex;*/
    flex-direction: column;
    align-items: center;
}


.reservationProjectionContainer p:first-child {
    margin-top: 10px; /* Dostosuj tę wartość do swoich potrzeb */
}


.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: row;
}

.courtRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}


.courtName {
    width: 115px;
    text-align: right;
    padding-right: 10px;
    position: relative;
    z-index: 1;
    font-weight: bold;
    margin-bottom: 5px;
}

.timeAndCells {
    display: flex;
    position: relative;
   }

.timeRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

.timeRow::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.cellsRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.labelText {
    margin-left: 1px;
}

.checkboxLabel {
    display: flex;
    align-items: center;
}

.checkboxInput {
    margin-right: 5px;
}

.checkboxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin-top: 5px;*/
    margin-bottom: 3px;
}

.modal {
    text-align: center;
}