.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner.small {
    width: 20px;
    height: 20px;
}

.spinner.medium {
    width: 36px;
    height: 36px;
}

.spinner.large {
    width: 52px;
    height: 52px;
}
