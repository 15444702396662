.superAdmin {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.table {
    width: 100%;
    border-collapse: collapse;
    display: block;
    overflow-x: auto;
}

table th, table td {
    border: 1px solid #ccc;
    padding: 0.5rem;
    text-align: left;
}

table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

button.deleteButton {
    background-color: #dc3545;
}

button.deleteButton:hover {
    background-color: #c82333;
}

@media screen and (max-width: 767px) {
    .table {
        font-size: 14px;
    }

    button {
        font-size: 14px;
        padding: 0.25rem 0.5rem;
    }

    input[type="checkbox"] {
        width: 24px;
        height: 24px;
    }
}

@media screen and (min-width: 768px) {
    .table {
        font-size: 16px;
    }

    button {
        font-size: 16px;
        padding: 0.5rem 1rem;
    }

    input[type="checkbox"] {
        width: 16px;
        height: 16px;
    }
}
